import Mixpanel from "mixpanel-browser"

const isTokenPresent = Boolean(process.env.REACT_APP_MIXPANEL_TOKEN)

const init = () => {
  if (!isTokenPresent) return

  Mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
}

const track = (event, object) => {
  console.log(event)
  if (!isTokenPresent) return

  Mixpanel.track(event, object)
}

const mixpanel = {
  init: init,
  track: track,
}

export default mixpanel
