const TARGET_ENV = process.env.REACT_APP_TARGET_ENV || "development"

const isEnv = env => () => TARGET_ENV === env

const isDevelopment = isEnv("development")
const isProduction = isEnv("production")
const isStaging = isEnv("staging")
const isTest = isEnv("test")
const isProductionLike = () => isProduction() || isStaging()

export const env = {
  isDevelopment,
  isProduction,
  isProductionLike,
  isStaging,
  isTest,
}
