const GTM_TOKEN = process.env.REACT_APP_GTM_TOKEN

const init = () => {
  if (!GTM_TOKEN) {
    console.warn("GTM_TOKEN not set.")
    return
  }

  const gtmScript = document.createElement("script")
  gtmScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_TOKEN}');`

  document.head.appendChild(gtmScript)
}

const track = object => {
  const dataLayer = window.dataLayer
  if (!dataLayer) return // TODO: handle this case explicitly with a warning?

  dataLayer.push(object)
}

const gtm = {
  init: init,
  track: track,
}

export default gtm
