const WIZARD_REFERRAL_CODE = "KP-Anfrage"
const COMPEON_LOGO_URL = "https://cdn.compeon.de/layouts/images/logo.svg"

export const Auth = {
  url: process.env.REACT_APP_AUTH_URL,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  loggedInCookieDomain: process.env.REACT_APP_AUTH_LOGGED_IN_COOKIE_DOMAIN,
  loggedInCookieName: process.env.REACT_APP_AUTH_LOGGED_IN_COOKIE_NAME,
}

Object.defineProperty(Auth, "passwordChangeUrl", {
  get: () => {
    const redirectUri = encodeURIComponent(window.location.href)
    return `${process.env.REACT_APP_AUTH_URL}/password/change?redirect_uri=${redirectUri}`
  },
})

export const Cdn = {
  getFontUrl: filePath => `https://cdn.compeon.de/layouts/fonts/${filePath}`,
  getImageUrl: filePath => `https://cdn.compeon.de/layouts/images/${filePath}`,
  compeonLogoUrl: COMPEON_LOGO_URL,
}

export const Cms = {
  termsAndConditionsUrl: process.env.REACT_APP_CMS_TERMS_AND_CONDITIONS_URL,
  privacyUrl: process.env.REACT_APP_CMS_PRIVACY_URL,
  imprintUrl: process.env.REACT_APP_CMS_IMPRINT_URL,
}

export const DocumentManager = {
  forInquiry: inquiryId =>
    `${process.env.REACT_APP_DOCUMENT_MANAGER_URL}/inquiries/${inquiryId}`,
  url: process.env.REACT_APP_DOCUMENT_MANAGER_URL,
}

export const Documents = {
  getDownloadUrl: (id, title, extension) => {
    const url = new URL(process.env.REACT_APP_DOCUMENTS_URL)

    url.pathname = `/${id}/download/${encodeURIComponent(title)}.${extension}`

    return url.toString()
  },
  url: process.env.REACT_APP_DOCUMENTS_URL,
}

export const Marketplace = {
  url: process.env.REACT_APP_API_URL,
}

export const Wizard = {
  getInquiryUrl,
  getNewInquiryUrl,
  url: process.env.REACT_APP_WIZARD_URL,
}

export const PreCheck = {
  url: process.env.REACT_APP_PRECHECK_URL,
}

export const services = {
  Auth,
  DocumentManager,
  Marketplace,
  Wizard,
  PreCheck,
}

for (const key in services) {
  if (!services[key].url)
    console.warn(`[${key}] Service URL is not configured.`)
}

function getNewInquiryUrl(companyId, queryParams = {}) {
  const uri = new URL(process.env.REACT_APP_WIZARD_URL)
  const { referralCode, ...rest } = queryParams

  const updatedQueryParams = {
    ...rest,
    "referral-code": referralCode || WIZARD_REFERRAL_CODE,
    ...(companyId && { "company-id": companyId }),
  }

  uri.pathname = "/inquiries/new"
  uri.search = new URLSearchParams(updatedQueryParams)

  return uri.toString()
}

function getInquiryUrl(inquiryId, queryParams = {}) {
  const uri = new URL(process.env.REACT_APP_WIZARD_URL)

  uri.pathname = `/inquiries/${inquiryId}`
  uri.search = new URLSearchParams(queryParams)

  return uri.toString()
}
