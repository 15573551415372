import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "config/rollbar"

import React from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"

import { waitForClientAuthentication } from "modules/compeonApi/client"
import { removeAuthParams } from "utils/history"
import { env } from "utils/env"
import { initializeTracking } from "./config/tracking"
import { loadIntlPolyfills } from "./config/intlPolyfills"

if (env.isDevelopment()) require("react-devtools")

async function render() {
  const { App } = await import("./App")
  ReactDOM.render(<App />, document.getElementById("root"))
}

Modal.setAppElement("#root")

waitForClientAuthentication
  .then(removeAuthParams)
  .then(initializeTracking)
  .then(loadIntlPolyfills)
  .then(render)
