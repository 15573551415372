import { env } from "utils/env"

import gtm from "./gtm"
import mixpanel from "./mixpanel"
import etmc from "./etmc"

export const initializeTracking = () => {
  gtm.init()
  mixpanel.init()

  if (env.isStaging()) etmc.init()
}

export const getTracker = eventName => data => mixpanel.track(eventName, data)

export { gtm, mixpanel, etmc }
