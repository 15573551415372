const ETMC_TOKEN: string = process.env.REACT_APP_ETMC_TOKEN || ""
const isTokenPresent = Boolean(ETMC_TOKEN)

export const init = () => {
  if (!isTokenPresent) {
    console.warn("ETMC_TOKEN not set.")
    return
  }

  const etmcScript = document.createElement("script")
  etmcScript.src = `//${ETMC_TOKEN}.collect.igodigital.com/collect.js`
  etmcScript.type = "text/javascript"

  if (!document.head) {
    console.warn("Unexpectedly missing head")
    return
  }

  document.head.appendChild(etmcScript)
}

const track = (payload: Array<string | number | Object>) => {
  const _etmc = window._etmc

  if (isTokenPresent && _etmc) _etmc.push(payload)
}

const etmc = {
  init,
  track,
}

export default etmc
