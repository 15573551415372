export async function removeAuthParams() {
  const cleanedUrl = new URL(window.location)
  const params = new URLSearchParams(cleanedUrl.search.substring(1))

  const paramsDefined = params.get("code") && params.get("state")
  if (!paramsDefined) return

  params.delete("code")
  params.delete("state")

  cleanedUrl.search = params

  window.history.replaceState({}, "", cleanedUrl)
}

export function hardReload() {
  document.location.reload(true)
}
