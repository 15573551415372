import CompeonAPI, { cookieAuthState } from "@compeon-os/api-client"
import { Auth, Marketplace } from "config/services"
import { deepCamelTransform } from "utils/object"
import { env } from "utils/env"

function assumeLoggedInDev() {
  document.cookie = `${Auth.loggedInCookieName}=1;domain=${document.location.hostname};`
}

function assumeLoggedOutDev() {
  document.cookie = `${Auth.loggedInCookieName}=0;domain=${document.location.hostname};max-age=-1;`
}

export const logout = () => {
  if (env.isDevelopment()) assumeLoggedOutDev()
  client.logout()
}

if (env.isDevelopment()) assumeLoggedInDev()

const invalidSessionCallbacks = []

const handleInvalidSession = () => {
  invalidSessionCallbacks.forEach(cb => cb())
}

export const registerInvalidSessionHandler = handler =>
  invalidSessionCallbacks.push(handler)

export const client = new CompeonAPI({
  baseURL: Marketplace.url,
  authConfig: {
    authState: cookieAuthState({ secure: env.isProductionLike(), path: "/" }),
    baseUrl: Auth.url,
    clientId: Auth.clientId,
    handleInvalidSession,
    loggedInCookieDomain: Auth.loggedInCookieDomain,
    loggedInCookieName: Auth.loggedInCookieName,
    withCookie: true,
  },
  transformResponse: [deepCamelTransform],
})

export const waitForClientAuthentication = client.authPromise
