import { shouldPolyfill as shouldPolyfillCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill"
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill"
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill"
import { shouldPolyfill as shouldPolyfillNumberFormat } from "@formatjs/intl-numberformat/should-polyfill"
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from "@formatjs/intl-datetimeformat/should-polyfill"

const LOCALE = "de-DE"

export const loadIntlPolyfills = async () => {
  if (shouldPolyfillCanonicalLocales()) {
    await import("@formatjs/intl-getcanonicallocales/polyfill")
  }

  if (shouldPolyfillLocale()) {
    await import("@formatjs/intl-locale/polyfill")
  }

  if (shouldPolyfillPluralRules(LOCALE)) {
    await import("@formatjs/intl-pluralrules/polyfill")
    await import("@formatjs/intl-pluralrules/locale-data/de")
  }

  if (shouldPolyfillNumberFormat(LOCALE)) {
    await import("@formatjs/intl-numberformat/polyfill")
    await import("@formatjs/intl-numberformat/locale-data/de")
  }

  if (shouldPolyfillDateTimeFormat(LOCALE)) {
    await import("@formatjs/intl-datetimeformat/polyfill")
    await import("@formatjs/intl-datetimeformat/locale-data/de")
  }
}
