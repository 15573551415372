import Rollbar from "rollbar"
import { env } from "utils/env"

const rollbarConfig: Object = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  enabled: env.isProductionLike(),
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: ["Kameleoon"],
  payload: {
    client: {
      javascript: {
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
    environment: process.env.REACT_APP_ROLLBAR_ENV,
  },
}

try {
  Rollbar.init(rollbarConfig)
} catch (error) {
  console.log(error)
}
